import { combineReducers, configureStore } from "@reduxjs/toolkit";
import analyticsReducer from "src/slices/analyticsSlice";
import brandReducer from "src/slices/brandSlice";
import clientReducer from "src/slices/clientSlice";
import selectCoachReducer from "src/slices/coachSlice";
import navigationReducer from "src/slices/navigationSlice";
import paymentReducer from "src/slices/paymentSlice";
import sharedWorkoutsReducer from "src/slices/sharedWorkoutsSlice";
import surveyReducer from "src/slices/surveySlice";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

const persistConfig = (key: string) => ({
  key: key,
  version: 8,
  storage: storageSession,
});

const reducers = combineReducers({
  sharedWorkouts: persistReducer(
    persistConfig("sharedWorkouts"),
    sharedWorkoutsReducer,
  ),
  survey: persistReducer(persistConfig("surveyReducer"), surveyReducer),
  client: persistReducer(persistConfig("clientReducer"), clientReducer),
  payment: persistReducer(persistConfig("paymentReducer"), paymentReducer),
  analytics: persistReducer(
    persistConfig("analyticsReducer"),
    analyticsReducer,
  ),
  coach: persistReducer(
    persistConfig("selectCoachReducer"),
    selectCoachReducer,
  ),
  navigation: persistReducer(persistConfig("navigation"), navigationReducer),
  brand: persistReducer(persistConfig("brandReducer"), brandReducer),
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
