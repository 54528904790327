import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  type SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectLocations, selectUserId } from "src/slices/clientSlice";
import CTAButton from "./Layout/CTAButton";

export default function Location() {
  const dispatch = useAppDispatch();
  const { nextPage } = useSurvey();
  const userId = useAppSelector(selectUserId);
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | undefined
  >();
  const locations = useAppSelector(selectLocations);

  function handleSelectLocation(event: SelectChangeEvent) {
    setSelectedLocationId(event.target.value as string);
  }

  const onClick = () => {
    if (selectedLocationId && userId) {
      api.clients.setLocationId(userId, selectedLocationId);
      nextPage();
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 4 }}>
        Which clinic location are you associated with?
      </Typography>
      <FormControl fullWidth>
        <InputLabel>Clinic Location</InputLabel>
        <Select
          value={selectedLocationId}
          onChange={handleSelectLocation}
          label="Clinic Location"
        >
          {locations?.map((location) => (
            <MenuItem key={location._id} value={location._id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CTAButton
        disabled={selectedLocationId === undefined}
        onClick={onClick}
        CTAText={"Next"}
      ></CTAButton>
    </Container>
  );
}
