export const logPodscribeLead = (email?: string) => {
  if (!email) return;
  if (typeof window === "undefined" && !window) return;
  if (!("podscribe" in window)) return;
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(email);

    crypto.subtle.digest("SHA-256", data).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string

      // @ts-expect-error
      window.podscribe("lead", {
        hashed_email: hashHex,
      });
    });
  } catch (err) {
    console.error(`❌ Failed to log PODSCRIBE LEAD with err:`, err);
  }
};
