import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectChosenOS } from "src/slices/surveySlice";

export default function Done() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));
  const chosenOS = useAppSelector(selectChosenOS);
  const trainerDependentTestIds = useAppSelector(
    (state) => state.coach.trainerDependentTestIds,
  );

  const isInRolexTest = trainerDependentTestIds.includes(
    "3ba7dd72-a755-471a-9b84-b8c982571199",
  );

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: { xs: "column" },
        height: "75vh",
        justifyContent: { xs: "start" },
        alignItems: "center",
        maxWidth: { sm: "sm" },
        mt: { xs: 0, sm: -2 },
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: "430px", sm: "500px" },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            my: 3,
            width: "100%",
            textAlign: "center",
          }}
        >
          Download the trainwell app to get started!
        </Typography>
        {isInRolexTest && (
          <Typography sx={{ textAlign: "center" }}>
            Your trainer will send you a message to schedule your first call.
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          {chosenOS === "ios" && (
            <a
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              href={
                "https://apps.apple.com/us/app/deltatrainer-fitness-training/id1466445684"
              }
            >
              {!isPhone && (
                <Box sx={{ mr: 4, display: "inline" }}>
                  <img
                    src="/assets/qr-codes/download-ios.svg"
                    alt="QR code to app store"
                    height={180}
                    width={180}
                  />
                </Box>
              )}
              <img
                src="/assets/download-on-the-app-store.svg"
                alt="Link to app store"
                height={90}
                width={170}
              />
            </a>
          )}
          {chosenOS === "android" && (
            <a
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              href={
                "https://play.google.com/store/apps/details?id=com.mycopilot.copilotapp"
              }
            >
              {!isPhone && (
                <Box sx={{ mr: 4, display: "inline" }}>
                  <img
                    src="/assets/qr-codes/download-android.svg"
                    alt="QR code to play store"
                    height={180}
                    width={180}
                  />
                </Box>
              )}
              <img
                src="/assets/google-play-store.svg"
                alt="Link to play store"
                height={90}
                width={185}
              />
            </a>
          )}
        </Box>
      </Box>
    </Container>
  );
}
