import type { TagExperience } from "@trainwell/types";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { selectActiveTests } from "src/slices/analyticsSlice";
import { selectTagExperience } from "src/slices/surveySlice";
import Tags from "./components/Tags";
import TagsPageLayout from "./components/TagsPageLayout";
import { experienceData } from "./data/experienceData";

export default function TagExperiencePage() {
  const dispatch = useAppDispatch();
  const { nextPage } = useSurvey();
  const tags = useAppSelector((state) => state.survey.tagExperience);
  const activeTestsIds = useAppSelector(selectActiveTests);

  function selectTag(tag: string) {
    dispatch(selectTagExperience(tag as TagExperience));
  }

  const onClick = () => {
    track("Experience Select", { tags: tags.length > 0 ? tags : ["empty"] });
    nextPage();
  };

  return (
    <TagsPageLayout
      title={`Special Trainer Experience`}
      subtitle={`Select any that you feel are important for your trainer to have experience
      in.`}
      onContinue={onClick}
    >
      <Tags tags={experienceData} onTagClick={selectTag} selected={tags} />
    </TagsPageLayout>
  );
}
