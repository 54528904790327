import { Box, Typography } from "@mui/material";
import type { CoachMatch } from "@trainwell/api-sdk";
import { getCoachName } from "src/lib/misc";
import CoachChips from "./CoachChips";

type Props = {
  coach: CoachMatch;
  chipData: { label: string; color: string; icon: JSX.Element }[];
};

export default function CoachInfo({ coach, chipData }: Props) {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderRadius: "87.5px",
            overflow: "hidden",
          }}
        >
          {coach.headshot_url_hd && (
            <img
              src={coach.headshot_url_hd}
              width={175}
              height={175}
              alt={getCoachName(coach)}
            />
          )}
        </Box>
      </Box>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        {getCoachName(coach)}
      </Typography>
      <CoachChips chips={chipData} />
    </div>
  );
}
