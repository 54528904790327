import { Box, Typography } from "@mui/material";

type Props = {
  dayLabel: string;
  dayNumber: number;
  times: string[];
};

export default function AvailabilityDay({ dayLabel, dayNumber, times }: Props) {
  const hasAvailability = times.length > 0;

  return (
    <Box
      sx={{
        border: hasAvailability ? "1px solid #DDD7EA" : undefined,
        background: hasAvailability ? undefined : "#EEE",
        borderRadius: "8px",
        padding: "8px",
        justifyContent: "start",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignContent: "center",
          justifyContent: "center",
          marginBottom: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "24px",
            width: "24px",
            borderRadius: "12px",
            background: hasAvailability ? "#443564" : "#888",
            color: "#FFF",
          }}
        >
          <Typography variant="caption" sx={{ fontWeight: "bold" }}>
            {dayNumber}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            marginTop: "-2px",
            color: hasAvailability ? "#443564" : "#888",
          }}
        >
          {dayLabel}
        </Typography>
      </Box>
      {times.map((time) => (
        <Typography
          sx={{ marginBottom: "6px", whiteSpace: "nowrap" }}
          //@ts-expect-error
          variant="body3"
          key={time}
        >
          {time}
        </Typography>
      ))}
    </Box>
  );
}
