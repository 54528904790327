import { Card, CardActionArea, Grid, Typography } from "@mui/material";
import type { TagExperience, TagGoal, TagIdentity } from "@trainwell/types";

type Tag = {
  label: string;
  key: string;
  icon: JSX.Element;
};

type TagsProps = {
  tags: Tag[];
  selected: string[];
  onTagClick: (
    key: string | TagIdentity | TagExperience | TagIdentity | TagGoal,
  ) => void;
};

const Tags = ({ tags, selected, onTagClick }: TagsProps) => {
  return (
    <Grid container spacing={2} sx={{ my: 1 }}>
      {tags.map((tag) => {
        const isSelected = selected.includes(tag.key);

        return (
          <Grid item xs={6} sm={4} key={tag.label}>
            <Card
              sx={{
                height: 65,
                backgroundColor: (theme) =>
                  isSelected ? theme.palette.secondary.main : undefined,
              }}
            >
              <CardActionArea
                onClick={() => {
                  onTagClick(tag.key);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  p: 2,
                }}
              >
                {tag.icon}
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: 16,
                    flex: 1,
                    lineHeight: "normal",
                  }}
                >
                  {tag.label}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Tags;
