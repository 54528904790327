import AccessibilityRoundedIcon from "@mui/icons-material/AccessibilityRounded";
import PregnantWomanRoundedIcon from "@mui/icons-material/PregnantWomanRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SentimentSatisfiedRoundedIcon from "@mui/icons-material/SentimentSatisfiedRounded";
import TransgenderRoundedIcon from "@mui/icons-material/TransgenderRounded";
import type { TagExperience as TagExperienceType } from "@trainwell/types";

export const experienceData: {
  label: string;
  key: TagExperienceType;
  icon: JSX.Element;
}[] = [
  {
    label: "Older populations",
    key: "older_clients",
    icon: <SentimentSatisfiedRoundedIcon />,
  },
  {
    label: "LGBTQ+",
    key: "lgbtq",
    icon: <TransgenderRoundedIcon />,
  },
  {
    label: "Pre/postnatal mothers",
    key: "pre_postnatal",
    icon: <PregnantWomanRoundedIcon />,
  },
  {
    label: "Plus size populations",
    key: "obesity",
    icon: <AccessibilityRoundedIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];
