import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import PanToolRoundedIcon from "@mui/icons-material/PanToolRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import VolunteerActivismRoundedIcon from "@mui/icons-material/VolunteerActivismRounded";
import type { TagCommunicationStyle as TagCommunicationStyleType } from "@trainwell/types";

const communicationData: {
  key: TagCommunicationStyleType;
  leftText: string;
  leftIcon: JSX.Element;
  rightText: string;
  rightIcon: JSX.Element;
}[] = [
  {
    key: "fun_to_focused",
    leftText: "Fun",
    leftIcon: <CelebrationRoundedIcon />,
    rightText: "Focused",
    rightIcon: <MenuBookRoundedIcon />,
  },
  {
    key: "empathetic_to_tough_love",
    leftText: "Empathetic",
    leftIcon: <VolunteerActivismRoundedIcon />,
    rightText: "Tough love",
    rightIcon: <PanToolRoundedIcon />,
  },
  {
    key: "emotional_to_analytical",
    leftText: "Emotional",
    leftIcon: <FavoriteRoundedIcon />,
    rightText: "Analytical",
    rightIcon: <SchoolRoundedIcon />,
  },
  // {
  //   key: "low_touch_to_high_touch",
  //   leftText: "Low touch",
  //   leftIcon: <PersonRoundedIcon />,
  //   rightText: "High touch",
  //   rightIcon: <PeopleRoundedIcon />,
  // },
  //
];

export default communicationData;
