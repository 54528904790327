import { Box, Container, Typography } from "@mui/material";
import React, { Fragment } from "react";
import CheckboxFeature from "src/components/misc/CheckboxFeature";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectActiveTests } from "src/slices/analyticsSlice";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import CTAButton from "../Layout/CTAButton";

type SplashContextText = {
  title: string;
  text: string;
};

type SplashPageProps = {
  title?: string;
  body?: string[];
  image?: React.ReactNode;
  contextText?: SplashContextText[];
  CTAText?: string;
  onClick: () => void;
};

export default function SplashPage({
  title = "Start your health journey!",
  body: text = [
    "We have a few questions so you can get paired with the perfect coach.",
  ],
  image = "/assets/copilot-small-moments.svg",
  contextText,
  CTAText = "Find My Coach",
  onClick = () => {},
}: SplashPageProps) {
  const { width } = useWindowDimensions();
  const isImage = typeof image === "string";
  const activeTestsIds = useAppSelector(selectActiveTests);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontWeight: "bold",
          mb: 4,
          "& b": { color: (theme) => theme.palette.primary.main },
        }}
      >
        {title}
      </Typography>
      <Box>
        {text.map((t, idx) => (
          <Typography
            key={t.slice(undefined, 10) + idx}
            sx={{
              mb: { xs: 2, sm: 3 },
              "& b": { color: (theme) => theme.palette.primary.main },
            }}
          >
            {t}
          </Typography>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
          height: {
            xs: isImage ? width - 74 : "auto",
            sm: (width - 30) / 2,
            md: "300px",
          },
          width: "100%",
          position: "relative",
        }}
      >
        {isImage ? (
          <Box
            sx={{
              height: "100%",
              width: "60%",
              position: "relative",
            }}
          >
            <img
              src={image}
              width={"100%"}
              height={"100%"}
              alt=""
              sizes="(max-width: 768px) 80vw,
              (max-width: 1200px) 40vw,
              33vw"
            />
          </Box>
        ) : (
          image
        )}
      </Box>

      <CTAButton onClick={onClick} CTAText={CTAText}></CTAButton>

      {contextText && (
        <Box sx={{ backgroundColor: "#ffffff", pt: 4 }}>
          {contextText.map(({ title, text }, index) => {
            return (
              <Fragment key={`${title}${index}`}>
                <CheckboxFeature title={title} content={text} />
                <Box sx={{ my: 4 }} />
              </Fragment>
            );
          })}
        </Box>
      )}
    </Container>
  );
}
