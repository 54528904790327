import { Container, Typography } from "@mui/material";
import CTAButton from "src/components/pages/Layout/CTAButton";

type TagsPageProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  onContinue: () => void;
  subText?: string;
};

export default function TagsPageLayout({
  title,
  subtitle,
  children,
  onContinue,
  subText,
}: TagsPageProps) {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
      {children}

      <CTAButton onClick={onContinue} CTAText={"Continue"}></CTAButton>

      {subText && (
        <Typography textAlign={"center"} sx={{ mb: 10 }}>
          {subText}
        </Typography>
      )}
    </Container>
  );
}
