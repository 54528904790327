import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch } from "src/hooks/stateHooks";
import { setGender as appSetGender } from "src/slices/surveySlice";

const possibleGenders = ["Man", "Woman", "Non-binary", "Prefer not to say"];

export default function Gender() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const [localGender, setGender] = useState<string>();

  function selectGender(gender: string) {
    dispatch(appSetGender(gender));
    survey.nextPage();
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 4 }}>
        How do you identify?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {possibleGenders.map((gender) => {
          return (
            <Button
              key={gender}
              sx={{
                mb: 2,
              }}
              onClick={() => {
                selectGender(gender);
              }}
            >
              {gender}
            </Button>
          );
        })}
        <TextField
          placeholder="Self identify"
          fullWidth
          color="primary"
          value={localGender}
          onChange={(event) => {
            setGender(event.target.value);
          }}
        />
      </Box>
      {localGender && (
        <Button
          sx={{
            my: 4,
          }}
          fullWidth
          disabled={!localGender || !localGender.replace(/\s/g, "").length}
          onClick={() => {
            selectGender(localGender);
          }}
        >
          Next
        </Button>
      )}
    </Container>
  );
}
