import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import type {
  Client,
  SubscriptionGroup,
  SubscriptionGroupOption,
} from "@trainwell/types";
import { getBrowser, getClientOS } from "src/lib/btracking";
import { getGAClientID } from "src/lib/gtag";
import { api } from "src/lib/trainwellApi";
import { setClient, setClientState } from "./clientSlice";
import type { RootState } from "./store";

export const fetchSubscriptionGroup = createAsyncThunk(
  "payment/fetchSubscriptionOptions",
  async (userId: string) => {
    const response = await api.subscriptionGroups.getForClient(userId);

    return response;
  },
);

export const subscribe = createAsyncThunk(
  "payment/subscribe",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    const subOptions = selectSubscriptionOptions(state);
    const selectedOption = selectedPlan(state);

    const freeTrialDays = selectFreeTrialDays(state);

    if (
      !state.survey.phoneNumber ||
      !subOptions ||
      !state.payment.subscriptionGroup
    ) {
      throw new Error(
        `Missing information on payment ${JSON.stringify({
          phoneNumber: state.survey.phoneNumber,
          subOptions,
        })}`,
      );
    }

    const googleClientId = await getGAClientID().catch(() => undefined);

    const response = await api.clients
      .subscribe(state.client.clientID!, {
        subscriptionGroupOptionId: subOptions[selectedOption].id,
        phoneNumber: state.survey.phoneNumber,
        trainerId: state.coach.selectedTrainer?.trainer_id!,
        trialDays: freeTrialDays,
        referralCode: state.analytics.referralCode ?? undefined,
        sessionId: state.analytics.sessionID ?? "none",
        coupon: state.payment.stripeCoupon,
        shouldNotTrial: false,
        googleClientId: googleClientId ?? undefined,
        isInterestedInFsaHsa: state.payment.isInterestedInFsaHsa,
        analyticsProps: {
          $current_url: location.href,
          path: location.pathname,
          hostname: location.hostname,
          sourceId: state.analytics.sourceID ?? undefined,
          utm_source_lt: state.analytics.utm.utmSource,
          utm_campaign_lt: state.analytics.utm.utmCampaign,
          utm_medium_lt: state.analytics.utm.utmMedium,
          utm_content_lt: state.analytics.utm.utmContent,
          $os: getClientOS(navigator.userAgent),
          $browser: getBrowser(navigator.userAgent, navigator.vendor),
        },
      })
      .catch((error) => {
        Sentry.captureException(error);

        throw error;
      });

    if (!response) {
      throw new Error(
        "finishPayment endpoint did not return any data when a response was expected",
      );
    }

    if ("user_id" in response) {
      dispatch(setClient(response));
    }

    if ("exist" in response) {
      dispatch(
        setClientState(
          response.state as Client["account"]["membership"]["state"],
        ),
      );
    }

    return response;
  },
);

export const setupFreeClient = createAsyncThunk(
  "app/setupFreeClient",
  async (_, { getState }) => {
    const state = getState() as RootState;

    if (!state.survey.phoneNumber) {
      throw new Error("Incorrect info");
    }

    const response = await api.clients.setupFree({
      userId: state.client.clientID!,
      trainerId: state.coach.selectedTrainer?.trainer_id!,
      phoneNumber: state.survey.phoneNumber,
      pairInfluencerUserId: state.client.pairInfluencerUserId,
    });

    return response;
  },
);

export const setupNoCardClient = createAsyncThunk(
  "app/setupNoCardClient",
  async (data: { gymLocation?: string }, { getState }) => {
    const { gymLocation } = data;
    const state = getState() as RootState;

    const subOptions = selectSubscriptionOptions(state);
    const selectedOption = selectedPlan(state);
    const freeTrialDays = selectFreeTrialDays(state);

    if (!state.survey.phoneNumber || !subOptions) {
      throw new Error("Incorrect info");
    }

    const response = await api.clients.setupNoCard(state.client.clientID!, {
      trainerId: state.coach.selectedTrainer?.trainer_id!,
      phoneNumber: state.survey.phoneNumber!,
      trialDays: freeTrialDays,
      subscriptionOption: subOptions[selectedOption] ?? {},
      referralCode: state.analytics.referralCode,
      sessionId: state.analytics.sessionID ?? "none",
      anytimeClubId: gymLocation,
    });

    return response;
  },
);

// Define a type for the slice state
interface PaymentState {
  subscriptionGroup: SubscriptionGroup | undefined;
  selectedSubOption: number;
  stripeCoupon: string | undefined;
  submitPaymentStatus:
    | "idle"
    | "loading"
    | "succeeded"
    | "succeeded-phone-exists"
    | "failed"
    | "account-error";
  submitPaymentError: string | undefined;
  codeID: string | undefined;
  trialDays: number;
  overrideSubscriptionOptions: SubscriptionGroupOption[] | null;
  isFree: boolean;
  forceNoCard: boolean;
  isInterestedInFsaHsa: boolean | undefined;
  errors: {
    submitPaymentError: string | SerializedError | undefined;
    subscriptionOptionsError: SerializedError | undefined;
  };
}

// Define the initial state using that type
const initialState: PaymentState = {
  stripeCoupon: undefined,
  subscriptionGroup: undefined,
  selectedSubOption: 0,
  submitPaymentStatus: "idle",
  submitPaymentError: undefined,
  codeID: undefined,
  trialDays: 14,
  overrideSubscriptionOptions: null,
  isFree: false,
  forceNoCard: false,
  isInterestedInFsaHsa: undefined,
  errors: {
    submitPaymentError: undefined,
    subscriptionOptionsError: undefined,
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetPayment: () => initialState,
    setCodeInfo: (
      state,
      action: PayloadAction<{
        codeID: string;
        overrideTrialDays?: number;
        overrideSubscriptionOptions: SubscriptionGroupOption[];
      }>,
    ) => {
      const { codeID, overrideTrialDays, overrideSubscriptionOptions } =
        action.payload;

      state.codeID = codeID;
      state.trialDays = overrideTrialDays ?? 14;
      if (
        overrideSubscriptionOptions &&
        overrideSubscriptionOptions.length > 0
      ) {
        state.overrideSubscriptionOptions = overrideSubscriptionOptions;
        state.selectedSubOption = overrideSubscriptionOptions.length - 1;
      }
    },
    setIsFree: (state, action: PayloadAction<boolean>) => {
      const isFree = action.payload;

      state.isFree = isFree;
    },
    setForceNoCard: (state, action: PayloadAction<boolean>) => {
      const forceNoCard = action.payload;

      state.forceNoCard = forceNoCard;
    },
    resetPaymentAttempt: (state) => {
      state.submitPaymentError = undefined;
      state.errors.submitPaymentError = undefined;
      state.submitPaymentStatus = "idle";
    },
    setSelectedSubOption: (state, action: PayloadAction<number>) => {
      state.selectedSubOption = action.payload;
    },
    setTrialDays: (state, action: PayloadAction<number>) => {
      console.log("setting trial days:", action.payload);
      state.trialDays = action.payload;
    },
    setStripeCoupon: (state, action: PayloadAction<string>) => {
      state.stripeCoupon = action.payload;
    },
    setIsInterestedInFsaHsa: (state, action: PayloadAction<boolean>) => {
      state.isInterestedInFsaHsa = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSubscriptionGroup.rejected, (state, action) => {
      console.log("Redux: Failed to get subscription");

      state.errors.subscriptionOptionsError = action.error;
    });
    builder.addCase(fetchSubscriptionGroup.fulfilled, (state, action) => {
      console.log("Redux: Got subscription info");

      const fetchedSubGroup = action.payload;

      if (fetchedSubGroup.options.length === 0) {
        state.errors.subscriptionOptionsError = {
          message: "No subscription options where returned by endpoint",
        };
        return;
      }

      state.subscriptionGroup = fetchedSubGroup;
    });
    builder.addCase(subscribe.pending, (state) => {
      state.submitPaymentStatus = "loading";
    });
    builder.addCase(subscribe.fulfilled, (state, action) => {
      console.log("Redux: Submitted payment");

      const response = action.payload;

      if ("user_id" in response) {
        state.submitPaymentStatus = "succeeded";
      } else {
        const { exists, accountError, state: clientState } = response;

        if (accountError) {
          state.submitPaymentStatus = "account-error";
          return;
        }

        if (exists) {
          state.submitPaymentStatus = "succeeded-phone-exists";
        }
      }
    });
    builder.addCase(subscribe.rejected, (state, action) => {
      state.submitPaymentStatus = "failed";
      state.submitPaymentError = action.error.message;
      state.errors.submitPaymentError = action.error;
    });
    builder.addCase(setupFreeClient.pending, (state) => {
      state.submitPaymentStatus = "loading";
    });
    builder.addCase(setupFreeClient.fulfilled, (state, action) => {
      console.log("Redux: Setup free client");

      if (!action.payload) {
        state.submitPaymentStatus = "succeeded";
        return;
      }

      const { exists } = action.payload;

      if (exists) {
        state.submitPaymentStatus = "succeeded-phone-exists";
      } else {
        state.submitPaymentStatus = "succeeded";
      }
    });
    builder.addCase(setupFreeClient.rejected, (state, action) => {
      state.submitPaymentStatus = "failed";
      state.submitPaymentError = action.error.message;
    });
    builder.addCase(setupNoCardClient.pending, (state) => {
      state.submitPaymentStatus = "loading";
    });
    builder.addCase(setupNoCardClient.fulfilled, (state, action) => {
      console.log("Redux: Setup no card client");

      if (!action.payload) {
        state.submitPaymentStatus = "succeeded";
        return;
      }

      const { exists } = action.payload;

      if (exists) {
        state.submitPaymentStatus = "succeeded-phone-exists";
      } else {
        state.submitPaymentStatus = "succeeded";
      }
    });
    builder.addCase(setupNoCardClient.rejected, (state, action) => {
      state.submitPaymentStatus = "failed";
      state.submitPaymentError = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetPayment,
  setCodeInfo,
  setIsFree,
  setForceNoCard,
  resetPaymentAttempt,
  setSelectedSubOption,
  setTrialDays,
  setStripeCoupon,
  setIsInterestedInFsaHsa,
} = paymentSlice.actions;

export default paymentSlice.reducer;
/**
 * Return subOptions array if it exist and has at least one item in it, else returns undefined
 * @param state
 * @returns
 */
export const selectSubscriptionOptions = (
  state: RootState,
): SubscriptionGroupOption[] | undefined => {
  if (
    state.payment.overrideSubscriptionOptions &&
    state.payment.overrideSubscriptionOptions.length > 0
  ) {
    return state.payment.overrideSubscriptionOptions;
  }
  if (
    state.payment.subscriptionGroup &&
    state.payment.subscriptionGroup.options.length > 0
  ) {
    return state.payment.subscriptionGroup.options.filter(
      (option) => option.default,
    );
  }
  return undefined;
};

export const selectedPlan = (state: RootState) =>
  state.payment.selectedSubOption;

export const selectFreeTrialDays = (state: RootState) => {
  if (state.analytics.brand === "af" || state.analytics.brand === "echelon") {
    return 30;
  } else {
    return state.payment.trialDays;
  }
};

export const selectIsNoCard = (state: RootState) => {
  if (
    state.analytics.brand === "af" ||
    state.analytics.brand === "echelon" ||
    state.payment.forceNoCard
  ) {
    return true;
  } else {
    return false;
  }
};

export const selectMonthlyPrice = (state: RootState) => {
  const subOptions = selectSubscriptionOptions(state);
  const selectedOption = selectedPlan(state);

  if (!subOptions) return undefined;

  if (subOptions.length > 0) {
    return subOptions[selectedOption].monthly_price;
  } else {
    return 99;
  }
};

export const selectIsFree = (state: RootState) => state.payment.isFree;
