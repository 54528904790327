import AccessibilityRoundedIcon from "@mui/icons-material/AccessibilityRounded";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SportsHandballRoundedIcon from "@mui/icons-material/SportsHandballRounded";
import type { TagSpecialty as TagSpecialtyType } from "@trainwell/types";

const specialtiesData: {
  label: string;
  key: TagSpecialtyType;
  icon: JSX.Element;
}[] = [
  {
    label: "Weight management",
    key: "weight_loss",
    icon: <AccessibilityRoundedIcon />,
  },
  {
    label: "Mobility & flexibility",
    key: "mobility_flexibility",
    icon: <EmojiPeopleRoundedIcon />,
  },
  {
    label: "Reducing fatigue",
    key: "reduce_fatigue",
    icon: <DirectionsRunRoundedIcon />,
  },
  {
    label: "Quality of life",
    key: "quality_of_life",
    icon: <SentimentSatisfiedAltRoundedIcon />,
  },
  {
    label: "Strength training",
    key: "strength",
    icon: <FitnessCenterRoundedIcon />,
  },
  {
    label: "Sports performance",
    key: "sports",
    icon: <SportsHandballRoundedIcon />,
  },
  {
    label: "General health",
    key: "general_health",
    icon: <FavoriteRoundedIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];

export default specialtiesData;
