import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "src/lib/trainwellApi";

export const fetchSharedWorkouts = createAsyncThunk(
  "sharedWorkouts/fetchSharedWorkouts",
  async (sharedWorkoutId: string) => {
    const sharedWorkouts =
      await api.sharedWorkouts.getSharedWorkout(sharedWorkoutId);

    const sharedWorkoutsUrl =
      await api.sharedWorkouts.getSharedWorkoutAppUrl(sharedWorkoutId);

    return { sharedWorkouts, sharedWorkoutsUrl };
  },
);

// Define a type for the slice state
interface SharedWorkoutsState {
  sharedWorkouts:
    | Awaited<ReturnType<(typeof api)["sharedWorkouts"]["getSharedWorkout"]>>
    | undefined;
  sharedWorkoutsUrl: string | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: SharedWorkoutsState = {
  sharedWorkouts: undefined,
  sharedWorkoutsUrl: undefined,
  status: "idle",
  error: undefined,
};

export const sharedWorkoutsSlice = createSlice({
  name: "sharedWorkouts",
  initialState,
  reducers: {
    resetSharedWorkouts: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSharedWorkouts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchSharedWorkouts.fulfilled, (state, action) => {
      console.log("Redux: got shared workouts");
      state.status = "succeeded";

      const { sharedWorkouts, sharedWorkoutsUrl } = action.payload;

      state.sharedWorkouts = sharedWorkouts;
      state.sharedWorkoutsUrl = sharedWorkoutsUrl.url;
    });
    builder.addCase(fetchSharedWorkouts.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetSharedWorkouts } = sharedWorkoutsSlice.actions;

export default sharedWorkoutsSlice.reducer;
