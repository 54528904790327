import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { trackEvent } from "src/slices/analyticsSlice";
import StyledDialog from "./StyledDialog";
export default function ErrorDialog({
  title,
  body,
  buttonCTA,
  onClick,
}: {
  title?: string;
  body?: string;
  buttonCTA?: string;
  onClick?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { client, analytics, payment, survey, coach } = useAppSelector(
    (state) => state,
  );
  const [displayed, setDisplayed] = useState(false);
  useEffect(() => {
    const filteredCoach = {
      ...coach,
      recommendedCoaches: coach.recommendedCoaches.map((coach) => ({
        trainer_id: coach.trainer_id,
        full_name: coach.full_name,
        first_name: coach.first_name,
        last_name: coach.last_name,
        onboarding_tags: coach.onboarding_tags ? 1 : 0,
        headshot_url_hd: coach.headshot_url_hd ? 1 : 0,
        short_about: coach.short_about ? 1 : 0,
        loves: coach.loves ? 1 : 0,
        education: coach.education ? 1 : 0,
        years_experience: coach.years_experience ? 1 : 0,
        availabilities_onboarding: coach.availabilities_onboarding ? 1 : 0,
        justification: coach.justification ? 1 : 0,
      })),
    };
    const newAnalytics = JSON.parse(JSON.stringify(analytics));
    newAnalytics.influencer = analytics.influencer ? undefined : undefined;
    if (displayed) {
      dispatch(
        trackEvent({
          event_type: "error_dialog_shown",
          event_content: {
            path: location.pathname,
            state: { client, newAnalytics, payment, survey, filteredCoach },
            location: location,
          },
        }),
      );
      track("Error Dialog Show", {
        path: location.pathname,
        url: location.href,
      });
    }
  }, [displayed]);

  useEffect(() => {
    setTimeout(() => {
      setDisplayed(true);
    }, 400);
  }, []);

  return (
    <StyledDialog
      title={title}
      body={body}
      buttonCTA={buttonCTA}
      onClick={onClick}
    />
  );
}

ErrorDialog.defaultProps = {
  title: "Something went wrong!",
  body: "Please contact our support team for help at support@trainwell.net or through the chat at the bottom right of the screen.",
  buttonCTA: "Reload app",
  onClick: function () {
    document.cookie = "; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();
    location.pathname = "/";
  },
};
