import { useEffect, useState } from "react";

type ParamsObject = Record<string, string | string[] | undefined>;

export function useUrlParams(...wantedParams: string[]) {
  const [params, setParams] = useState<ParamsObject>({});

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlParamObject = wantedParams.reduce((acc, param) => {
      const paramSearch = urlParams.getAll(param);
      let value;
      if (paramSearch.length === 1) {
        value = paramSearch[0];
      } else if (paramSearch.length === 0) {
        value = undefined;
      } else {
        value = paramSearch;
      }
      return { ...acc, [param]: value };
    }, {});
    setParams(urlParamObject);
  }, []);

  return params;
}
