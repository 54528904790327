import { Discount } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import type { Trainer } from "@trainwell/types";
import { useMemo } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { useUrlParams } from "src/hooks/useUrlParams";
import { selectActiveTests } from "src/slices/analyticsSlice";
import { selectFreeTrialDays } from "src/slices/paymentSlice";

const buildHeaderCopy = ({
  trainer,
  trialDays,
  coupon,
  referralCampaign,
  activeTestIds,
}: {
  trainer?: Trainer;
  trialDays: number;
  coupon?: string | string[];
  referralCampaign?: string;
  activeTestIds: string[];
}): string | undefined => {
  let offer: string | undefined = undefined;

  const monthOff =
    activeTestIds.includes("sr_offer_v3_applied_a") ||
    coupon === "Eylkptwh" ||
    (Array.isArray(coupon) && coupon.includes("Eylkptwh"));
  const threeMonthsOff =
    coupon === "md9OwRDm" ||
    (Array.isArray(coupon) && coupon.includes("md9OwRDm"));
  const twelveMonthsOff =
    coupon === "gHc3TYGR" ||
    (Array.isArray(coupon) && coupon.includes("gHc3TYGR"));
  const twentyPercentOff =
    coupon === "1J87JxZV" ||
    (Array.isArray(coupon) && coupon.includes("1J87JxZV"));
  const fiftyPercentOffReferral = referralCampaign === "referral_v3.0";
  const fiftyPercentOffSR =
    coupon === "6WoVpy97" ||
    (Array.isArray(coupon) && coupon.includes("6WoVpy97"));
  const fiftyDollarsOffSR =
    coupon === "ckh5c7xM" ||
    (Array.isArray(coupon) && coupon.includes("ckh5c7xM"));
  const amountOff25 =
    coupon === "btc0eabd" ||
    (Array.isArray(coupon) && coupon.includes("btc0eabd"));

  if (trialDays > 0) {
    offer =
      trainer === undefined
        ? `${trialDays} day free trial`
        : `${trialDays} day trial`;
  }

  if (!offer && !trainer) {
    return undefined;
  }

  if (!offer && trainer) {
    return trainer.first_name;
  }

  if (monthOff) {
    offer += " + free month";
  } else if (threeMonthsOff) {
    offer += " + free 3 months";
  } else if (twelveMonthsOff) {
    offer += " + free 12 months";
  } else if (twentyPercentOff) {
    offer += " + 20% off";
  } else if (fiftyPercentOffReferral || fiftyPercentOffSR) {
    offer += " + 50% off";
  } else if (fiftyDollarsOffSR) {
    offer += " + $50 off";
  } else if (amountOff25) {
    offer += " + $25 off";
  }

  if (trainer) {
    offer += ` with ${trainer.first_name}`;
  }

  return offer;
};

export function DiscountSticky({ trainer }: { trainer?: Trainer }) {
  const activeTestIds = useAppSelector(selectActiveTests);
  const trialDays = useAppSelector(selectFreeTrialDays);
  const referralCampaign = useAppSelector(
    (state) => state.analytics.referralCampaign,
  );
  const { coupon } = useUrlParams("coupon");

  const offerCopy = useMemo(() => {
    return buildHeaderCopy({
      trainer,
      trialDays,
      coupon,
      referralCampaign,
      activeTestIds,
    });
  }, [trainer, trialDays, coupon, referralCampaign, activeTestIds]);

  if (!offerCopy) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "48px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        background: "#EDF6FD",
        color: "#443564",
        gap: "8px",
        borderBottom: "2px solid",
        borderColor: "#EFEFEF",
      }}
    >
      {trainer ? (
        <Avatar
          src={trainer.headshot_url}
          sx={{ width: "32px", height: "32px" }}
        />
      ) : (
        <Discount />
      )}
      <Typography style={{ fontWeight: 500 }}>{offerCopy}</Typography>
    </Box>
  );
}
