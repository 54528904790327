import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useSurvey } from "src/components/Survey";
import CTAButton from "../Layout/CTAButton";

type Props = {
  title: React.ReactNode;
  content: React.ReactNode;
  imageSrc: string | undefined;
};

export default function Snippet({
  title,
  content,
  imageSrc = undefined,
}: Props) {
  const survey = useSurvey();

  function clickedNext() {
    survey.nextPage();
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          mb: 2,
          "& b": { color: (theme) => theme.palette.primary.main },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 4,
          "& b": { color: (theme) => theme.palette.primary.main },
        }}
      >
        {content}
      </Typography>
      {imageSrc && (
        <Box
          sx={{
            my: 6,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={imageSrc} width={350} height={180} alt="" />
        </Box>
      )}
      <CTAButton onClick={clickedNext} CTAText={"Continue"}></CTAButton>
    </Container>
  );
}
