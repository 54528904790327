import { Box, Button, Container, Typography } from "@mui/material";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch } from "src/hooks/stateHooks";
import type { SurveyInterface } from "src/slices/surveySlice";
import { setActivityFrequency } from "src/slices/surveySlice";

export default function ActivityFrequency() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();

  const possibleAnswers: {
    id: SurveyInterface["activityFrequency"];
    name: string;
  }[] = [
    { id: "not_at_all", name: "Not at all" },
    { id: "few_month", name: "A few times per month" },
    { id: "once_week", name: "Once a week" },
    { id: "2_to_3_week", name: "2 to 3 times per week " },
    { id: "4_plus_week", name: "4+ per times week " },
  ];

  function selectFrequency(frequency: SurveyInterface["activityFrequency"]) {
    dispatch(setActivityFrequency(frequency));
    survey.nextPage();
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 1 }}>
        How often do you exercise?
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        No exercise experience is required!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {possibleAnswers.map((option) => {
          return (
            <Button
              key={option.id}
              sx={{
                mb: 2,
              }}
              onClick={() => {
                selectFrequency(option.id);
              }}
            >
              {option.name}
            </Button>
          );
        })}
      </Box>
    </Container>
  );
}
