import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import { DiscountSticky } from "src/components/misc/DiscountSticky/DiscountSticky";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectActiveTests } from "src/slices/analyticsSlice";
import { selectBrand } from "src/slices/brandSlice";
import { selectIsFree } from "src/slices/paymentSlice";

export default function NavBar() {
  const location = useLocation();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const activeTestIds = useAppSelector(selectActiveTests);
  const forcedTrainer = useAppSelector((state) => state.coach.forcedTrainer);
  const brand = useAppSelector(selectBrand);
  const isFree = useAppSelector(selectIsFree);
  const shouldHideSticky =
    isFree ||
    (location.pathname === "/" &&
      activeTestIds.includes("jul_youtube_splash_treatment"));

  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        background: "transparent",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        {!shouldHideSticky && <DiscountSticky trainer={forcedTrainer} />}
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            justifyContent: isPhone ? "center" : "start",
            width: "100%",
            marginLeft: isPhone ? 0 : "16px",
          }}
        >
          {brand && (
            <>
              <img src={brand.logo_url} alt="partner-logo" height={37} />
              <Box
                sx={{
                  height: 37,
                  width: "1px",
                  mx: "8px",
                  background: "#443564",
                }}
              />
            </>
          )}
          <a href={"https://www.trainwell.net/" + location.search}>
            <img
              style={{ marginTop: "-3px" }}
              src="/assets/trainwell-wordmark.svg"
              alt="logo"
              width={125}
              height={37}
            />
          </a>
        </Box>
      </Box>
    </Box>
  );
}
