import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSurvey } from "src/components/Survey";
import ErrorDialog from "src/components/misc/ErrorDialog";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectActiveTests } from "src/slices/analyticsSlice";
import {
  selectSelectedAvailability,
  selectTrainerFirstName,
} from "src/slices/coachSlice";
import {
  selectFreeTrialDays,
  selectIsFree,
  selectMonthlyPrice,
  selectSubscriptionOptions,
} from "src/slices/paymentSlice";
import { selectFirstName } from "src/slices/surveySlice";
import LocalTrainerPlan from "./LocalTrainerPlan";
import Plan from "./Plan";

export default function PlanContainer() {
  const { nextPage } = useSurvey();
  const isFree = useAppSelector(selectIsFree);
  const freeTrialDays = useAppSelector(selectFreeTrialDays);
  const userFirstName = useAppSelector(selectFirstName);
  const trainerFirstName = useAppSelector(selectTrainerFirstName);
  const monthlyPrice = useAppSelector(selectMonthlyPrice);
  const brand = useAppSelector((state) => state.analytics.brand);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedAvailability = useAppSelector(selectSelectedAvailability);
  const activeTestsIds = useAppSelector(selectActiveTests);

  const subscriptionOptions = useAppSelector(selectSubscriptionOptions);

  const urlParams = new URLSearchParams(window.location.search);
  const isLocalTrainerAds =
    urlParams.get("utm_campaign") === "local_pt_conversions";

  useEffect(() => {
    if (selectedAvailability.availability !== "") {
      searchParams.set("pa", selectedAvailability.availability);
      setSearchParams(searchParams);
    }
  }, [selectedAvailability]);

  if (brand === "gf") {
    nextPage();
  }

  if (!subscriptionOptions || !monthlyPrice) {
    return (
      <ErrorDialog title="Something went wrong, we are missing some important data!" />
    );
  }

  const novDisplayCopy = {
    title: `${userFirstName ? userFirstName + `'s ` : "Your "} Trainwell Plan`,
    monthlyPrice: isFree ? "0" : `${monthlyPrice}`,
    buttonCopy: isFree ? "Get started" : "Try for $0.00",
    checkBoxItems: [
      {
        title: "Personalized Workouts",
        body: "Tailored to your goals, schedule, and injuries.",
      },
      {
        title: "1-on-1 Support & Guidance",
        body:
          `Unlimited messaging and calls with your trainer` +
          (trainerFirstName ? `, ${trainerFirstName}.` : "."),
      },
      {
        title: "Long-Term Results",
        body: "Build your health, strength, and confidence.",
      },
      {
        title: "Start For Free. Cancel Anytime.",
        body: `$0.00 due today. Only pay after your ${freeTrialDays} day free trial.`,
        highlightBody: activeTestsIds.includes("dec_trial_reminder_treatment")
          ? " We'll remind you before your trial ends."
          : undefined,
      },
    ],
  };

  if (isFree) {
    novDisplayCopy.checkBoxItems = novDisplayCopy.checkBoxItems.slice(0, 3);
  }

  if (isLocalTrainerAds) {
    return (
      <LocalTrainerPlan displayCopy={novDisplayCopy} onClickNext={nextPage} />
    );
  } else {
    return <Plan displayCopy={novDisplayCopy} onClickNext={nextPage} />;
  }
}
