export const frontChatIdentify = ({
  fullName,
  userId,
}: {
  fullName: string;
  userId?: string;
}) => {
  if (typeof window === "undefined" && !window) return;
  if (!("FrontChat" in window)) return;
  try {
    // @ts-expect-error
    window.FrontChat("identity", {
      chatId: import.meta.env.VITE_FRONT_CHAT_ID,
      useDefaultLauncher: true,
      name: fullName,
      customFields: {
        copilot_user_id: userId,
        copilot_dash_link: `https://trainer.mycopilot.com/admin/company/clients/${userId}`,
      },
    });
  } catch (err) {
    console.error(`❌ Failed to log IDENTIFY on FRONTCHAT with err:`, err);
  }
};
