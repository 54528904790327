import { CssBaseline, useTheme } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { updateApiOptions } from "@trainwell/features";
import { SnackbarProvider } from "notistack";
import type { ReactNode } from "react";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "src/slices/store";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);
updateApiOptions({
  prefixUrl: import.meta.env.VITE_API_DOMAIN,
  headers: { "api-key": import.meta.env.VITE_TRAINWELL_API_KEY },
});

const persistor = persistStore(store);

type Props = {
  children: ReactNode;
};

export default function AppProviders({ children }: Props) {
  const theme = useTheme();

  return (
    <PersistGate loading={null} persistor={persistor}>
      <CssBaseline />
      <Elements
        stripe={stripePromise}
        options={{
          mode: "setup",
          currency: "usd",
          setupFutureUsage: "off_session",
          fonts: [
            {
              family: "CircularStd",
              src: "url(https://join.trainwell.net/fonts/circular-std-book.woff2)",
              style: "normal",
              weight: "400",
            },
          ],
          appearance: {
            variables: {
              colorPrimary: theme.palette.primary.main,
              colorDangerText: theme.palette.error.main,
              fontFamily: theme.typography.fontFamily,
              borderRadius: `${theme.shape.borderRadius}px`,
              colorText: theme.palette.text.primary,
              colorTextSecondary: theme.palette.text.secondary,
              fontWeightNormal: theme.typography.fontWeightRegular?.toString(),
            },
            rules: {
              ".Input": {
                borderColor: "#DEDEDE",
                borderWidth: "2px",
                boxShadow: "",
              },
              ".Tab": {
                borderColor: "#e9e9e9",
                borderWidth: "1px",
                boxShadow: "",
              },
            },
          },
        }}
      >
        <SnackbarProvider autoHideDuration={5000}>{children}</SnackbarProvider>
      </Elements>
    </PersistGate>
  );
}
