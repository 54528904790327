import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { store } from "src/slices/store";
import getTheme from "src/theme/theme";
import "src/theme/theme.css";
import App from "./App";

Sentry.init({
  dsn: "https://d27618f915154fdbb75c79a339782093@o505736.ingest.sentry.io/6209423",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      stripBasename: true,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/main\.nodejs\.trainwellapis\.com/,
    /^https:\/\/dev\.nodejs\.trainwellapis\.com/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Provider store={store}>
        <ThemeProvider theme={getTheme("light")}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    </div>
  </React.StrictMode>,
);
