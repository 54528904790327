import type { Theme } from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import CircularStdBlack from "src/assets/fonts/circular-std-black.woff2";
import CircularStdBold from "src/assets/fonts/circular-std-bold.woff2";
import CircularStdBook from "src/assets/fonts/circular-std-book.woff2";
import CircularStdLight from "src/assets/fonts/circular-std-light.woff2";

// Create a theme instance.
export default function getTheme(themeMode: string): Theme {
  // Hardcoded until dark mode looks good
  themeMode = "light";

  const darkBlue = "#443564";

  return responsiveFontSizes(
    createTheme({
      palette: {
        mode: themeMode === "dark" ? "dark" : "light",
        background: {
          default: "#ffffff",
        },
        primary: {
          main: darkBlue,
        },
        secondary: {
          main: "#DDD7EA",
        },
        success: {
          main: "#379764",
        },
        error: {
          main: "#EC4835",
        },
        text: {
          primary: "#252525",
          secondary: "#767676",
        },
        divider: "#E9E9E9",
        backgroundSecondary: {
          main: "#EEE",
        },
        backgroundTertiary: {
          main: "#f7f7f7",
        },
      },
      typography: {
        fontFamily: "'CircularStd', sans-serif",
        // @ts-expect-error
        YTBig: {
          fontSize: 70,
          fontWeight: 900,
          color: "#443564",
        },
        h1: {
          fontSize: 32,
          fontWeight: 900,
          color: "#443564",
        },
        h2: {
          fontSize: 32,
          fontWeight: 900,
          color: "#443564",
        },
        h3: {
          fontSize: 24,
          fontWeight: 900,
          color: "#443564",
        },
        h4: {
          fontSize: 19,
          fontWeight: 700,
          color: "#443564",
        },
        subtitle1: {
          fontSize: 18,
          lineHeight: 1.5,
        },
        subtitle2: {
          fontSize: 17,
          textTransform: "uppercase",
        },
        body1: {
          fontSize: 18,
        },
        body2: {
          fontSize: 22,
          fontWeight: 300,
        },
        body3: {
          fontSize: 14,
          fontWeight: 300,
          lineHeight: 1,
        },
        tinyCaption: {
          fontSize: 14,
          fontWeight: 800,
        },
        popupBody: {
          fontSize: 40,
          fontWeight: 300,
        },
      },
      shape: {
        borderRadius: 10,
      },
      components: {
        MuiCard: {
          defaultProps: {
            variant: "outlined",
          },
        },
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
            font-family: 'CircularStd';
            font-style: normal;
            font-display: swap;
            font-weight: 300;
            src: local('CircularStd'), local('CircularStd-Light'), url(${CircularStdLight}) format('woff2');
          }

          @font-face {
            font-family: 'CircularStd';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('CircularStd'), local('CircularStd-Book'), url(${CircularStdBook}) format('woff2');
          }
            
            @font-face {
              font-family: 'CircularStd';
              font-style: normal;
              font-display: swap;
              font-weight: 700;
              src: local('CircularStd'), local('CircularStd-Bold'), url(${CircularStdBold}) format('woff2');
            }

            @font-face {
              font-family: 'CircularStd';
              font-style: normal;
              font-display: swap;
              font-weight: 900;
              src: local('CircularStd'), local('CircularStd-Black'), url(${CircularStdBlack}) format('woff2');
            }
          `,
        },
        MuiButton: {
          defaultProps: {
            variant: "contained",
            disableElevation: true,
          },
          styleOverrides: {
            root: {
              fontFamily: "CircularStd",
              fontWeight: 700,
              textTransform: "none",
              fontSize: 18,
            },
            contained: {
              // backgroundColor: "#9CDEFF",
              paddingTop: 12,
              paddingBottom: 12,
              color: "#ffffff",
            },
            text: {
              "&:hover": {
                backgroundColor: "rgba(54,42,80, 0.2)",
              },
            },
          },
          variants: [
            {
              props: { variant: "selected" },
              style: {
                paddingTop: 12,
                paddingBottom: 12,
                backgroundColor: "#ffffff",
                boxShadow: "0 0 0 2px " + darkBlue,
                "&:active": {
                  backgroundColor: "#ffffff",
                  boxShadow: "0 0 0 2px " + darkBlue,
                },
                "&:hover": {
                  backgroundColor: "#ffffff",
                  boxShadow: "0 0 0 2px " + darkBlue,
                },
              },
            },
          ],
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderWidth: "2px",
              borderColor: "#DEDEDE",
            },
          },
        },
        MuiLink: {
          defaultProps: {
            sx: {
              textDecoration: "none",
              color: (theme) => theme.palette.primary.main,
              ":hover": {
                color: (theme) => theme.palette.secondary.main,
              },
            },
          },
        },
      },
    }),
  );
}

declare module "@mui/material/styles" {
  interface Palette {
    backgroundSecondary: Palette["primary"];
    backgroundTertiary: Palette["primary"];
  }

  interface PaletteOptions {
    backgroundSecondary?: PaletteOptions["primary"];
    backgroundTertiary?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    selected: true;
  }
}
