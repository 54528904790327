import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectActiveTests } from "src/slices/analyticsSlice";
import { setupClient } from "src/slices/clientSlice";
import { getPotentialCoaches } from "src/slices/coachSlice";

export default function FindingCoach() {
  const survey = useSurvey();
  const dispatch = useAppDispatch();
  const [progress, setProgress] = useState(0);
  const [doneLoading, setDoneLoading] = useState(false);
  const submitSurveyStatus = useAppSelector(
    (state) => state.client.submitSurveyStatus,
  );
  const coachMatchesStatus = useAppSelector(
    (state) => state.coach.potentialCoachesStatus,
  );

  const activeTestsIds = useAppSelector(selectActiveTests);

  useEffect(() => {
    if (submitSurveyStatus === "succeeded") {
      dispatch(setupClient());
    }
    if (coachMatchesStatus === "idle" || coachMatchesStatus === "failed") {
      dispatch(getPotentialCoaches({ count: 3 }));
    }
  }, [coachMatchesStatus, submitSurveyStatus, dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100 && !doneLoading) {
          setDoneLoading(true);
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (doneLoading) {
      survey.nextPage();
    }
  }, [doneLoading]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h1" sx={{ mb: 8 }}>
        Hold tight, we&apos;re finding your perfect trainer!
      </Typography>
      {activeTestsIds.includes("july_sexy_loading_treatment") ? (
        <Box
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            display: "flex",
            width: "100%",
          }}
        >
          <video
            style={{ position: "absolute", inset: "15px auto 15px auto" }}
            width={"140"}
            height={"140"}
            autoPlay
            loop
          >
            <source src="/assets/sexy_pf_loading.mp4" />
          </video>
          <CircularProgress
            sx={{
              position: "absolute",
              color: "#775FAB",
              inset: "0px auto 0px auto",
            }}
            size={200}
            thickness={2}
            variant="determinate"
            value={progress}
          />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </Container>
  );
}
