import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import FemaleRoundedIcon from "@mui/icons-material/FemaleRounded";
import MaleRoundedIcon from "@mui/icons-material/MaleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import TransgenderRoundedIcon from "@mui/icons-material/TransgenderRounded";
import type { TagIdentity as TagIdentityType } from "@trainwell/types";

const identityData: {
  label: string;
  key: TagIdentityType;
  icon: JSX.Element;
}[] = [
  {
    label: "Male",
    key: "male",
    icon: <MaleRoundedIcon />,
  },
  {
    label: "Female",
    key: "female",
    icon: <FemaleRoundedIcon />,
  },
  {
    label: "Non-binary",
    key: "non_binary",
    icon: <TransgenderRoundedIcon />,
  },
  {
    label: "Older",
    key: "older",
    icon: <ArrowUpwardRoundedIcon />,
  },
  {
    label: "Younger",
    key: "younger",
    icon: <ArrowDownwardRoundedIcon />,
  },
  // {
  //   label: "Spanish speaking",
  //   key: "language_spanish",
  //   icon: <PublicRoundedIcon />,
  // },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];

export default identityData;
