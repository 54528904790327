import type { ApiOptions } from "@trainwell/api-sdk";
import { createApi } from "@trainwell/api-sdk";

const options: ApiOptions = {
  apiUrl: import.meta.env.VITE_API_DOMAIN,
  pythonApiUrl: import.meta.env.VITE_PYTHON_SERVER_BASE_URL,
  apiKey: import.meta.env.VITE_TRAINWELL_API_KEY,
};

if (typeof window !== "undefined" && window) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const apiDev = urlParams.get("api-dev");

  if (apiDev === "true") {
    if (!import.meta.env.VITE_DEVAPI_DOMAIN) {
      throw new Error("Dev api url not specified");
    }

    options.apiUrl = import.meta.env.VITE_DEVAPI_DOMAIN;
  }
}

export const api = createApi(options);
