import ErrorDialog from "src/components/misc/ErrorDialog";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectActiveTests } from "src/slices/analyticsSlice";
import { selectSubscriptionOptions } from "src/slices/paymentSlice";
import Pay from "./Pay";

export default function PayPageContainer() {
  const subOptions = useAppSelector(selectSubscriptionOptions);
  const activeTestsIds = useAppSelector(selectActiveTests);

  if (!subOptions) {
    return (
      <ErrorDialog title="Something went wrong, we are missing some important data!" />
    );
  }

  // Don't show plans that are hidden or that have a tenure requirement (since clients have zero tenure in onboarding)
  const filteredSubOptions = subOptions.filter(
    (option) => !option.is_hidden_on_pay_page && !option.required_tenure_months,
  );

  return <Pay subOptions={filteredSubOptions} />;
}
