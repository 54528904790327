import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { resetSelectedTrainer, selectTrainerName } from "src/slices/coachSlice";
import { selectChosenOS, setOwnsSmartWatch } from "src/slices/surveySlice";

const possibleAnswers = ["Yes", "No"];

export default function SmartWatch() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const chosenOS = useAppSelector(selectChosenOS);
  const selectedTrainer = useAppSelector(selectTrainerName);

  const isAndroid = chosenOS === "android";

  useEffect(() => {
    if (selectedTrainer) {
      dispatch(resetSelectedTrainer());
    }
  }, []);

  function selectAnswer(answer: string) {
    let ownsSmartWatch = false;

    if (answer === "Yes") {
      ownsSmartWatch = true;
    }

    dispatch(setOwnsSmartWatch(ownsSmartWatch));
    survey.nextPage();
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 1 }}>
        {isAndroid ? "Do you own a smartwatch?" : "Do you own an Apple Watch?"}
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }}>
        If not, no worries! You don&apos;t need one.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {possibleAnswers.map((answer) => {
          return (
            <Button
              key={answer}
              sx={{
                mb: 2,
              }}
              onClick={() => {
                selectAnswer(answer);
              }}
            >
              {answer}
            </Button>
          );
        })}
      </Box>
    </Container>
  );
}
