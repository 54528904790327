import { Box, CircularProgress } from "@mui/material";
import type React from "react";
import { useSurvey } from "src/components/Survey";
import ErrorDialog from "src/components/misc/ErrorDialog";
import { useAppSelector } from "src/hooks/stateHooks";
import { useUrlParams } from "src/hooks/useUrlParams";
import {
  selectActiveTestIdsStatus,
  selectActiveTests,
  selectReferrerName,
} from "src/slices/analyticsSlice";
import { selectFreeTrialDays } from "src/slices/paymentSlice";
import SplashPage from "./SplashPage";
import SplashPageYoutubeV2 from "./SplashPageYoutubeV2";
import SplashPage_ForcedTrainer from "./SplashPage_ForcedTrainer";
import SplashPage_BetterHelp from "./SplashPage_TwoPanel_BetterHelp";
import SplashPage_TwoPanel_Loved from "./SplashPage_TwoPanel_Loved";

const LoadingComponent = ({ children }: { children?: React.ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <CircularProgress color={"primary"} />
    {children}
  </Box>
);

export default function SplashPageContainer() {
  const { nextPage } = useSurvey();
  const influencerStatus = useAppSelector(
    (state) => state.analytics.status.influencerStatus,
  );
  const influencer = useAppSelector((state) => state.analytics.influencer);
  const brand = useAppSelector((state) => state.analytics.brand);
  const referrer_name = useAppSelector(selectReferrerName);
  const haveTestLoaded =
    useAppSelector(selectActiveTestIdsStatus) === "succeeded";
  const freeTrialDays = useAppSelector(selectFreeTrialDays);
  const sharedWorkouts = useAppSelector(
    (state) => state.sharedWorkouts.sharedWorkouts,
  );

  const forcedTrainerId = useAppSelector(
    (state) => state.coach.forcedTrainerId,
  );
  const forcedTrainer = useAppSelector((state) => state.coach.forcedTrainer);
  const getOneCoachStatus = useAppSelector(
    (state) => state.coach.getOneCoachStatus,
  );

  const activeTestsIds = useAppSelector(selectActiveTests);

  const {
    source,
    referral_id: rid,
    referralCode: rcode,
    mattsamazingtest,
    googlesearch1,
  } = useUrlParams(
    "source",
    "referral_id",
    "referral_code",
    "mattsamazingtest",
    "googlesearch1",
  );

  if (!(haveTestLoaded && influencerStatus === "succeeded")) {
    console.log("SPLASH: Loading...");
    return (
      <LoadingComponent>
        {influencerStatus === "failed" && <ErrorDialog />}
      </LoadingComponent>
    );
  }

  const onClick = () => {
    nextPage();
  };

  if (forcedTrainerId && getOneCoachStatus === "loading") {
    return <LoadingComponent />;
  }

  if (forcedTrainerId && forcedTrainer) {
    return (
      <SplashPage_ForcedTrainer trainer={forcedTrainer} onClick={onClick} />
    );
  }

  if (sharedWorkouts?.id) {
    return (
      <SplashPage
        title={"Welcome to trainwell"}
        body={[
          `We'll ask you a few questions to personalize your free account.`,
        ]}
        image={"/assets/freemium-dots.svg"}
        CTAText={"Continue"}
        onClick={onClick}
      />
    );
  }

  if (rid || rcode) {
    return (
      <SplashPage
        title={"Finally be consistent with fitness"}
        body={[
          `Thanks to your friend${
            referrer_name ? ` ` + referrer_name : ""
          }, your first 30 days of trainwell are completely free.`,
        ]}
        image={
          <Box
            sx={{
              height: "354px",
              width: "300px",
              position: "relative",
              mt: 2,
            }}
          >
            <img
              src={"/assets/cneier_2phones.png"}
              width={"100%"}
              height={"100%"}
              alt=""
              sizes="(max-width: 768px) 80vw,
            (max-width: 1200px) 40vw,
            33vw"
            />
          </Box>
        }
        CTAText={"Let's Get Started"}
        onClick={onClick}
        contextText={contextText}
      />
    );
  }

  if (mattsamazingtest || googlesearch1) {
    return (
      <SplashPage
        title={"Finally be consistent with fitness"}
        body={[
          `Your first ${freeTrialDays} days of trainwell are completely free.`,
        ]}
        image={
          <Box
            sx={{
              height: "354px",
              width: "300px",
              position: "relative",
              mt: 2,
            }}
          >
            <img
              src={"/assets/cneier_2phones.png"}
              width={"100%"}
              height={"100%"}
              alt=""
              sizes="(max-width: 768px) 80vw,
            (max-width: 1200px) 40vw,
            33vw"
            />
          </Box>
        }
        CTAText={`Find my trainer`}
        onClick={onClick}
        contextText={contextText}
      />
    );
  }

  if (!influencer) {
    return (
      <SplashPage_TwoPanel_Loved
        titleCopy="Start your fitness journey!"
        bodyCopy={`We have a few questions so you can get paired with the perfect trainer.`}
        ctaCopy={`Find my trainer`}
        onClick={onClick}
      />
    );
  } else if (influencer) {
    if (activeTestsIds.includes("jul_youtube_splash_treatment")) {
      return (
        <SplashPage_BetterHelp
          influencer={influencer}
          ctaCopy={`Find my trainer`}
          onClick={onClick}
        />
      );
    } else {
      return (
        <SplashPage_TwoPanel_Loved
          titleCopy={`${influencer.display_name} approved`}
          bodyCopy={`We're happy you're here! Let's get started by matching you with a trainer.`}
          influencer={influencer}
          ctaCopy={`Find my trainer`}
          onClick={onClick}
        />
      );
    }
  }

  if (influencer) {
    return <SplashPageYoutubeV2 influencer={influencer} onClick={onClick} />;
  }

  const title =
    brand === "af"
      ? "Hello from trainwell and Anytime Fitness!"
      : brand === "echelon"
        ? "Hello from trainwell and Echelon!"
        : brand === "gf"
          ? "Hello from trainwell and Husk!"
          : `Start your fitness journey!`;

  return (
    <SplashPage
      title={title}
      body={[
        `We have a few questions so you can get paired with the perfect trainer.`,
      ]}
      image={"/assets/little-sad-man-writing.svg"}
      CTAText={`Find my trainer`}
      onClick={onClick}
    />
  );
}

const bodyText = [
  ["We have a few questions so you can get paired with the perfect trainer."],
  [
    "Join the 15,000+ clients that have taken a step towards their fitness goal with trainwell!",
  ],
];

const contextText = [
  {
    title: "Accountability from a 1-on-1 trainer",
    text: "Once you pick the perfect trainer, they will be there with you every step of the way to support you.",
  },
  {
    title: "Beginner friendly",
    text: "You don’t need any fitness experience or equipment to succeed with trainwell. Most of our clients start with basic movements in their living room.",
  },
  {
    title: "Fits into your busy life",
    text: "At trainwell we believe the best workout is the one you’ll do - even if that’s just a 10 minute walk in between your second job and picking up your kids from school.",
  },
];
