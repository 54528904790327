import { Box, Chip } from "@mui/material";

type Props = {
  chips: { label: string; color: string; icon: JSX.Element }[];
};

export default function CoachChips({ chips }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0,
        m: 0,
      }}
      component="ul"
    >
      {chips.map((data) => {
        if (data.label === "") {
          return;
        }
        return (
          <Chip
            key={data.label}
            icon={data.icon}
            label={data.label}
            sx={{
              mt: "8px",
              mr: "8px",
              backgroundColor: data.color,
              color: (theme) => theme.palette.text.primary,
              ".MuiChip-iconColorDefault": {
                color: (theme) => theme.palette.text.primary,
              },
              ".MuiChip-label": {
                fontWeight: 500,
              },
            }}
          />
        );
      })}
    </Box>
  );
}
