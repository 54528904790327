import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import type { TagCommunicationStyle as TagCommunicationStyleType } from "@trainwell/types";

type SliderSetProps = {
  data: {
    key: TagCommunicationStyleType;
    leftText: string;
    leftIcon: JSX.Element;
    rightText: string;
    rightIcon: JSX.Element;
  }[];
  selectedTags: { key: string; value: number }[];
  setTag: (key: string, value: number) => void;
};

export default function SliderSet({
  data,
  setTag,
  selectedTags,
}: SliderSetProps) {
  return (
    <>
      {data.map((tag, index) => (
        <Box key={index} sx={{ my: 2 }}>
          <Grid container>
            <Grid
              item
              xs={6}
              sm={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Stack spacing={2} direction="row" alignItems="center">
                {tag.leftIcon}
                <Typography>{tag.leftText}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} order={{ xs: 3, sm: 2 }}>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <FormControl
                  component="fieldset"
                  fullWidth
                  hiddenLabel
                  margin="none"
                >
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    value={
                      (selectedTags.filter((t) => t.key === tag.key)[0]
                        ?.value ?? 0.5) * 4
                    }
                    onChange={(event) => {
                      const value = Number(
                        (event.target as HTMLInputElement).value,
                      );

                      setTag(tag.key, value / 4);
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label=""
                      sx={{ m: 0 }}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label=""
                      sx={{ m: 0 }}
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label=""
                      sx={{ m: 0 }}
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label=""
                      sx={{ m: 0 }}
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label=""
                      sx={{ m: 0 }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              order={{ xs: 2, sm: 3 }}
              sx={{
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack spacing={2} direction="row" alignItems="center">
                <Typography sx={{ textAlign: "right" }}>
                  {tag.rightText}
                </Typography>
                {tag.rightIcon}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
}
