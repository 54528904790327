import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Colander } from "@trainwell/colander";
import { useEffect } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { api } from "src/lib/trainwellApi";
import { selectActiveTests } from "src/slices/analyticsSlice";
import { resetSelectedAvailability } from "src/slices/coachSlice";

export default function ScheduleCall() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const theme = useTheme();
  const user_id = useAppSelector((state) => state.client.clientID);
  const trainer = useAppSelector((state) => state.coach.selectedTrainer);
  const trainerDependentTestIds = useAppSelector(
    (state) => state.coach.trainerDependentTestIds,
  );
  const activeTestsIds = useAppSelector(selectActiveTests);

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParamSkipCall = Boolean(
      JSON.parse(urlParams.get("skip_call") ?? "false") ?? false,
    );

    const skipCallDueToRolex = trainerDependentTestIds.includes(
      "3ba7dd72-a755-471a-9b84-b8c982571199",
    );

    if (queryParamSkipCall || skipCallDueToRolex) {
      survey.nextPage();
    }
  });

  if (!user_id || !trainer) {
    return <Typography>Something went wrong</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ px: 2 }}>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Schedule Your First Call
        </Typography>
        {/* Kate gets special 60 min calls cause of CPT2 */}
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          {`Please don't be late to your call, ${trainer?.first_name} booked ${trainer.trainer_id === "810a6c50-dad6-49b5-860b-0f27d007911e" ? "60" : "40"} minutes in their busy schedule to get to know you!`}
        </Typography>
      </Box>
      {trainer ? (
        <Container maxWidth="md" sx={{ py: 4 }}>
          <Colander
            variant={isSmall ? "box" : "card"}
            coach={trainer}
            userId={user_id}
            disableCallTypeSelection
            disableCheckinToggle
            defaultCallType={"onboarding"}
            onFinishBooking={() => {
              track("Onboarding Call Scheduled");
              dispatch(resetSelectedAvailability());
              survey.nextPage();
            }}
            api={api}
            onPickedAvailabilityFail={() => {
              dispatch(resetSelectedAvailability());
            }}
            enableAvailabilityApology={activeTestsIds.includes(
              "availability_apology_treatment",
            )}
          />
        </Container>
      ) : (
        <CircularProgress />
      )}
      <img
        src={`https://www.shareasale.com/sale.cfm?tracking=${user_id}&amount=99.00&merchantID=106416&transtype=sale`}
        width="1"
        height="1"
      />
      <script
        src="https://www.dwin1.com/19038.js"
        type="text/javascript"
        defer
      ></script>
      {/* iHeart Pixel */}
      <img
        src={`https://arttrk.com/pixel/?ad_log=referer&action=purchase&order_id=${user_id}&pixid=666318fe-9985-4fba-a112-4ae321b43f77`}
        width="1"
        height="1"
        style={{ border: "0" }}
      />
    </Box>
  );
}
