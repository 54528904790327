import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
export default function StyledDialog({
  title,
  body,
  buttonCTA,
  onClick,
}: {
  title?: string;
  body?: string;
  buttonCTA?: string;
  onClick?: () => void;
}) {
  return (
    <Dialog
      open={true}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClick}>
          {buttonCTA}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StyledDialog.defaultProps = {
  title: "Title",
  body: "Body text here",
  buttonCTA: "Click",
  onClick: () => {},
};
