import { Box, Typography } from "@mui/material";
import { useAppSelector } from "src/hooks/stateHooks";

export default function BrandFooter() {
  const brand = useAppSelector((state) => state.analytics.brand);

  return (
    <Box sx={{ flex: 0 }}>
      {brand && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pb: 2,
            pt: 2,
          }}
        >
          <Typography>In partnership with</Typography>
          {brand === "af" ? (
            <img
              src="/assets/anytime-fitness.png"
              alt="logo"
              width={151}
              height={37}
            />
          ) : brand === "echelon" ? (
            <img src="/assets/echelon.png" alt="logo" width={232} height={37} />
          ) : brand === "gf" ? (
            <img
              src="/assets/globalfit.png"
              alt="logo"
              width={213}
              height={100}
            />
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
}
