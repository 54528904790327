import { Route, Routes } from "react-router-dom";
import Survey from "src/components/Survey";
import SharedWorkoutPage from "src/components/freemium/SharedWorkoutPage";

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path="claim-shared-workouts/:sharedWorkoutId"
        element={<SharedWorkoutPage />}
      />
      <Route path="*" element={<Survey />} />
    </Routes>
  );
}
