import { LoadingPage } from "@trainwell/ui";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ErrorDialog from "src/components/misc/ErrorDialog";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { setClientID } from "src/slices/clientSlice";
import { setPagePaths } from "src/slices/navigationSlice";
import { selectSubscriptionOptions } from "src/slices/paymentSlice";
import { setChosenOS, setPhoneNumber } from "src/slices/surveySlice";
import Pay from "../Pay/Pay";

export default function RegisterPageContainer() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user_id");
  const subOptions = useAppSelector(selectSubscriptionOptions);
  const subOptionsError = useAppSelector(
    (state) => state.payment.errors.subscriptionOptionsError,
  );
  const phoneNumber = useAppSelector((state) => state.survey.phoneNumber);

  useEffect(() => {
    dispatch(
      setPagePaths([
        {
          path: "/register",
          active: true,
        },
        {
          path: "/done",
          active: true,
        },
      ]),
    );
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(setClientID(userId));

      api.clients.getOne(userId).then((client) => {
        dispatch(setChosenOS(client.device_platform));
        dispatch(setPhoneNumber(client.phone_number ?? ""));

        if (!client.phone_number) {
          throw new Error("No phone number found for user");
        }

        if (!client.device_platform) {
          throw new Error("No device platform found for user");
        }
      });
    }
  }, [userId, dispatch]);

  if (subOptionsError) {
    return (
      <ErrorDialog title="Something went wrong, we are missing some important data!" />
    );
  }

  if (!subOptions || !phoneNumber) {
    return <LoadingPage />;
  }

  if (!userId) {
    return (
      <ErrorDialog title="Something went wrong, we are missing your user id!" />
    );
  }

  return <Pay subOptions={subOptions} />;
}
