import * as Sentry from "@sentry/react";
import { useStripe } from "@stripe/react-stripe-js";
import type { SetupIntent } from "@stripe/stripe-js";
import type { TrainwellApi } from "@trainwell/api-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TrainwellError } from "../errors/TrainwellError";

type Props = {
  api: TrainwellApi;
  userId: string;
  onSuccess: () => void;
  onFail?: () => void;
};

export function useValidatePaymentMethod({
  api,
  userId,
  onSuccess,
  onFail,
}: Props) {
  const stripe = useStripe();
  const [searchParams] = useSearchParams();
  const [handled, setHandled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [validationMessage, setValidationMessage] = useState<string>();

  function validateSetupIntent(
    setupIntent: SetupIntent,
    makeDefault?: boolean,
  ) {
    // Inspect the SetupIntent `status` to indicate the status of the payment
    // to your customer.
    //
    // Some payment methods will [immediately succeed or fail][0] upon
    // confirmation, while others will first enter a `processing` state.
    //
    // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
    switch (setupIntent?.status) {
      case "succeeded":
        api.paymentMethods
          .validate(setupIntent.payment_method as string, {
            makeDefault: makeDefault,
          })
          .then(async () => {
            enqueueSnackbar("Success! Your payment method has been saved", {
              variant: "success",
            });

            setValidationMessage(undefined);

            onSuccess();
          })
          .catch(() => {
            Sentry.captureException(
              new TrainwellError({
                name: "PaymentError",
                message: "Failed to validate payment method",
                extraData: {
                  user_id: userId,
                },
              }),
            );

            setValidationMessage(
              "Failed to validate your payment method. Please try another payment method.",
            );

            onFail && onFail();
          });

        break;

      case "processing":
        enqueueSnackbar("Processing", { variant: "info" });

        Sentry.captureException(
          new TrainwellError({
            name: "PaymentError",
            message: "Processing payment info",
            extraData: {
              user_id: userId,
            },
          }),
        );

        setValidationMessage(
          "We're processing your payment method, check back later",
        );

        break;

      case "requires_payment_method":
        // Redirect your user back to your payment page to attempt collecting
        // payment again
        Sentry.captureException(
          new TrainwellError({
            name: "PaymentError",
            message:
              "Failed to process payment details. Please try another payment method.",
            extraData: {
              user_id: userId,
            },
          }),
        );

        setValidationMessage(
          "Failed to process payment details. Please try another payment method.",
        );

        onFail && onFail();

        break;
    }
  }

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = searchParams.get("setup_intent_client_secret");
    const makeDefault = JSON.parse(
      searchParams.get("make_default") ?? "false",
    ) as boolean;

    if (!clientSecret || handled) {
      return;
    }

    setHandled(true);

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      if (setupIntent) {
        validateSetupIntent(setupIntent, makeDefault);
      } else {
        Sentry.captureException(
          new TrainwellError({
            name: "PaymentError",
            message:
              "Failed to fetch payment details. Please try another payment method.",
            extraData: {
              user_id: userId,
            },
          }),
        );

        setValidationMessage(
          "Failed to fetch payment details. Please try another payment method.",
        );

        onFail && onFail();
      }
    });
  }, [stripe, searchParams]);

  return { validationMessage, validateSetupIntent };
}
