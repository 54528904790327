import { useAppSelector } from "src/hooks/stateHooks";
import { selectGoalTags } from "src/slices/surveySlice";
import TestimonialSnippet from "../Snippet/TestimonialSnippet";

export default function ValuePageContainer_Testimonials() {
  const goalTags = useAppSelector(selectGoalTags);

  if (goalTags.includes("improve_health")) {
    return (
      <TestimonialSnippet
        title={"Our clients improve their health"}
        content={
          <>
            Working with trainwell makes you <b>9x</b> more likely to reach your
            health goals.
          </>
        }
        testimonialClientName={"Robert"}
        testimonialClientDescription={"client since 2021"}
        testimonialClientHeadshotUrl={"/assets/testimonials/robert.jpg"}
        testimonialCopy={
          "Trainwell has changed my life. I've never been able to make lasting physical progress because I'd always drop off a workout plan after a few weeks. I've been working with trainwell for 3 years and am feeling the best I've ever felt in my life."
        }
      />
    );
  }

  if (goalTags.includes("lose_weight")) {
    return (
      <TestimonialSnippet
        title={"Our clients lose weight for good."}
        content={
          <>
            Working with trainwell makes you <b>9x</b> more likely to reach your
            health goals.
          </>
        }
        testimonialClientName={"Sandra"}
        testimonialClientDescription={"client since 2024"}
        testimonialClientHeadshotUrl={"/assets/testimonials/sandra.jpg"}
        testimonialCopy={
          "Trainwell has changed my life. Thanks to my trainer Kris, I've stuck longer with this than any other plan and I'm down 41lbs in the first 6 months!"
        }
      />
    );
  }

  if (goalTags.includes("build_strength")) {
    return (
      <TestimonialSnippet
        title={"Our clients build strength and confidence"}
        content={
          <>
            <b>89%</b> of clients report increased strength.
          </>
        }
        testimonialClientName={"Sandra"}
        testimonialClientDescription={"client since 2022"}
        testimonialClientHeadshotUrl={"/assets/testimonials/sandra.jpg"}
        testimonialCopy={
          "I am 56 years old and thanks to my trainer, Jill, I am in the best shape of my life! My clothes fit differently and my strength has improved significantly!"
        }
      />
    );
  }

  if (goalTags.includes("be_consistent")) {
    return (
      <TestimonialSnippet
        title={"Our clients are consistent"}
        content={
          <>
            <b>92%</b> of clients report being more consistent with exercise.
          </>
        }
        testimonialClientName={"Robert"}
        testimonialClientDescription={"client since 2021"}
        testimonialClientHeadshotUrl={"/assets/testimonials/robert.jpg"}
        testimonialCopy={
          "Trainwell has changed my life. I've never been able to make lasting physical progress because I'd always drop off a workout plan after a few weeks. I've been working with trainwell for 3 years and am feeling the best I've ever felt in my life."
        }
      />
    );
  }

  if (goalTags.includes("look_more_fit")) {
    return (
      <TestimonialSnippet
        title={"Our clients lose fat and build muscle"}
        content={
          <>
            Working with trainwell makes you <b>9x</b> more likely to reach your
            fitness goals.
          </>
        }
        testimonialClientName={"Yulie"}
        testimonialClientDescription={"client since 2023"}
        testimonialClientHeadshotUrl={"/assets/testimonials/yulie.jpg"}
        testimonialCopy={
          "I am so grateful that I was able to find this program, my trainer is so amazing; with her help and encouragement I have lost over 30lbs and was able to summit Mt. Fuji!"
        }
      />
    );
  }

  if (goalTags.includes("break_through_plateaus")) {
    return (
      <TestimonialSnippet
        title={"Our clients lose fat and build muscle"}
        content={
          <>
            Working with trainwell makes you <b>9x</b> more likely to reach your
            fitness goals.
          </>
        }
        testimonialClientName={"Yulie"}
        testimonialClientDescription={"client since 2023"}
        testimonialClientHeadshotUrl={"/assets/testimonials/yulie.jpg"}
        testimonialCopy={
          "I am so grateful that I was able to find this program, my trainer is so amazing; with her help and encouragement I have lost over 30lbs and was able to summit Mt. Fuji!"
        }
      />
    );
  }

  if (goalTags.includes("enjoy_exercise")) {
    return (
      <TestimonialSnippet
        title={"Our clients discover exercise they love"}
        content={
          <>The average trainwell client completes 3 workouts per week.</>
        }
        testimonialClientName={"Rebecca"}
        testimonialClientDescription={"client since 2023"}
        testimonialClientHeadshotUrl={"/assets/testimonials/rebecca.jpg"}
        testimonialCopy={
          "I used to hate strength training, but have really, truly enjoyed the workouts. I feel like I get something out of them ever time and Jill really caters them to what I need."
        }
      />
    );
  }

  if (goalTags.includes("age_gracefully")) {
    return (
      <TestimonialSnippet
        title={"Our clients don't let age define them"}
        content={
          <>
            <b>21%</b> of trainwell clients are 50+ years old and stronger than
            ever.
          </>
        }
        testimonialClientName={"Lisette"}
        testimonialClientDescription={"client since 2020"}
        testimonialClientHeadshotUrl={"/assets/testimonials/lisette.jpg"}
        testimonialCopy={
          "Having an accountability partner helped me more than I expected it would. I'm now leaner and stronger than ever (at 52!)"
        }
      />
    );
  }

  if (goalTags.includes("increase_confidence")) {
    return (
      <TestimonialSnippet
        title={"Our clients build strength and confidence"}
        content={
          <>
            <b>89%</b> of clients report increased strength.
          </>
        }
        testimonialClientName={"Sandra"}
        testimonialClientDescription={"client since 2022"}
        testimonialClientHeadshotUrl={"/assets/testimonials/sandra.jpg"}
        testimonialCopy={
          "I am 56 years old and thanks to my trainer, Jill, I am in the best shape of my life! My clothes fit differently and my strength has improved significantly!"
        }
      />
    );
  }

  if (goalTags.includes("overcome_injury")) {
    return (
      <TestimonialSnippet
        title={"Our clients recover quickly"}
        content={
          <>
            Your trainer will work with you to rebuild your strength and
            mobility.
          </>
        }
        testimonialClientName={"Elizabeth"}
        testimonialClientDescription={"client since 2023"}
        testimonialClientHeadshotUrl={"/assets/testimonials/elizabeth.jpg"}
        testimonialCopy={
          "I have a chronic injury and I've gotten so strong in just 3-4 months! My pain is so much less than it was and I can enjoy more and more activities."
        }
      />
    );
  }

  return (
    <TestimonialSnippet
      title={"Our clients reach their goals"}
      content={
        <>
          Working with trainwell makes you <b>9x</b> more likely to reach your
          fitness and health goals.
        </>
      }
      testimonialClientName={"Berlyn"}
      testimonialClientDescription={"client since 2021"}
      testimonialClientHeadshotUrl={"/assets/testimonials/berlyn.jpg"}
      testimonialCopy={
        "As someone who has always struggled with working out, I can confidently say that trainwell is a gamechanger. It lets me adapt exercise to my life, not the other way around."
      }
    />
  );
}
